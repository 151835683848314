<template>
    <div class="editSite">
        <a-spin :spinning="loading">
            <div class="flex space alcenter">
                <div class="ft20 cl-main ftw600">{{getTitle}}</div>
                <a-button @click="$router.go(-1)">返回</a-button>
            </div>

            <div class="mt20 bg-w pd40">
                <div style="width: 1000px;">
                    <a-form-model :model="form"  ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                        <a-form-model-item ref="name" required label="站点名称" help="20字以内" :hasFeedback="true" prop="name">
                            <a-input v-model="form.name" :maxLength="30" placeholder="输入站点名称" style="width: 480px;"></a-input>
                        </a-form-model-item>
                        <a-form-model-item ref="site_abbreviation"  label="站点简称" help="20字以内" :hasFeedback="true" prop="site_abbreviation">
                            <a-input v-model="form.site_abbreviation" :maxLength="30" placeholder="输入站点简称" style="width: 480px;"></a-input>
                        </a-form-model-item>
                        <a-form-model-item required label="绑定站长" ref="account_id" prop="account_id" >
                            <a-select v-model="form.account_id"  show-search style="width:200px" placeholder="请选择数据" option-filter-prop="children">
                                <a-select-option :value="0" :key="-1">
                                    站长列表
                                </a-select-option>
                                <a-select-option :value="item.account_id" v-for="(item,index) in account_list" :key="index">
                                    {{item.username}} -- {{item.mobile}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item required label="站点分类" ref="site_type_id" prop="site_type_id">
                            <a-select v-model="form.site_type_id"  style="width:480px" placeholder="请选择分类" mode="multiple" >
                                <a-select-option :value="0" :key="-1">
                                    选择分类
                                </a-select-option>
                                <a-select-option :value="item.site_type_id" v-for="(item,index) in type_list" :key="index">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item required label="站点类型" ref="site_type" prop="site_type">
                            <a-select v-model="form.site_type" style="width:200px" placeholder="选择自营或者入驻">
                                <a-select-option :value="0" :key="-1" >
                                    选择类型
                                </a-select-option>
                                <a-select-option :value="1" :key="0">官方自营</a-select-option>
                                <a-select-option :value="2" :key="1">入驻站点</a-select-option>
                            </a-select>
                        </a-form-model-item>




                        <a-form-model-item label="营业状态">
                            <a-radio-group v-model="form.is_run">
                                <a-radio :value="0">营业中</a-radio>
                                <a-radio :value="1">暂停营业</a-radio>
                            </a-radio-group>
                        </a-form-model-item>

                        <a-form-model-item required label="站点LOGO" ref="site_logo" prop="site_logo">
                            <div class="ft12 ftw400 cl-notice">建议上传240x240px</div>
                            <div class="mt10 ml20">
                                <upload-img v-model="form.site_logo"></upload-img>
                            </div>
                        </a-form-model-item>

                        <a-form-model-item required label="营业执照" ref="business_license" prop="business_license">
                            <div class="ft12 ftw400 cl-notice">支持上传图片为10M</div>
                            <div class="mt10 ml20">
                                <upload-img v-model="form.business_license"></upload-img>
                            </div>
                        </a-form-model-item>


                        <a-form-model-item required label="电话" help="可以是座机或者手机号" ref="site_phone" prop="site_phone">
                            <a-input-number v-model="form.site_phone" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
                        </a-form-model-item>

                        <a-form-model-item label="营业时间">
                            <a-time-picker :default-value="moment(form.start_time, 'HH:mm:ss')"  @change="(val,dateStrings)=>changeTime(val,dateStrings,'start_time')"/>
                            <a-time-picker :default-value="moment(form.end_time, 'HH:mm:ss')" @change="(val,dateStrings)=>changeTime(val,dateStrings,'end_time')"/>
                        </a-form-model-item>

                        <a-form-model-item required label="站点地址" prop="province_id">
                            <a-select v-model="form.province_id" style="width: 120px" @change="getRegion" >
                                <a-select-option  v-for="province in provinceData" :key="province.id" data-level="2">
                                    {{ province.name }}
                                </a-select-option>
                            </a-select>

                            <a-select style="width: 120px" v-model="form.city_id" @change="getRegion" placeholder="请选择市">
                                <a-select-option  v-for="city in cityData" :key="city.id" data-level="3">
                                    {{ city.name }}
                                </a-select-option>
                            </a-select>

                            <a-select v-model="form.district_id" style="width: 120px" @change="getRegion" placeholder="请选择区/县">
                                <a-select-option v-for="district in districtData" :key="district.id" data-level="4">
                                    {{ district.name }}
                                </a-select-option>
                            </a-select>
                            <a-select v-model="form.street_id" style="width: 160px" placeholder="请选择所属街道">
                                <a-select-option v-for="street in streetData" :key="street.id" data-level="5">
                                    {{ street.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item required label="详细地址" prop="address">
                            <a-input-group >
                                <a-row :gutter="8">
                                    <a-col :span="8"><a-input v-model="form.address" placeholder="请输入详细地址" /></a-col>
                                    <a-col :span="8"><baidu-map :lat="form.lat" :lng="form.lng" :keyword1="form.address" @select="selectAct"></baidu-map></a-col>
                                </a-row>

                            </a-input-group>
                        </a-form-model-item>

                        <a-form-model-item required label="地图定位" prop="lat">
                            <a-input-group >
                                <a-row :gutter="8">
                                    <a-col :span="8"><a-input v-model="form.lat" :value="form.lat" addon-before="经度"/></a-col>
                                    <a-col :span="8"><a-input v-model="form.lng" :value="form.lng" addon-before="纬度"/></a-col>
                                </a-row>

                            </a-input-group>
                        </a-form-model-item>

                        <a-form-model-item required label="到期时间" prop="expire_time">
                            <a-date-picker
                                format="YYYY-MM-DD"
                                :disabled-date="disabledDate"
                                @change="(val,dateStrings)=>changeTime(val,dateStrings,'expire_time')"
                                :default-value="moment(form.expire_time, dateFormat)"
                                :value="form.expire_time"
                            />
                        </a-form-model-item>

                        <a-form-model-item label="站点设施" required class="time-set">
                            <a-form-model-item
                                v-for="(domain, index) in form.domains"
                                :key="index"
                                v-bind="index === 0 ? formItemLayout : {}"
                                style="width: 80%;"
                            >
                                <a-input
                                    v-model="domain.value"
                                    placeholder="请输入设施名称"
                                    style="width: 35%; margin-right: 8px"
                                >

                                </a-input>
                                <upload-img class="upload-icon" v-model="domain.icon"></upload-img>

                            <a-icon
                                v-if="form.domains.length > 1"
                                class="dynamic-delete-button"

                                type="minus-circle-o"
                                :disabled="form.domains.length === 1"
                                @click="removeDomain(domain)"
                            />

                            </a-form-model-item>
                            <a-form-model-item>
                                <a-button type="dashed" style="width: 50%" @click="addDomain">
                                    <a-icon type="plus"/>
                                    添加站点设施
                                </a-button>
                            </a-form-model-item>
                        </a-form-model-item>


                        <a-form-model-item label="排序" help="数值越大排序越高">
                            <a-input-number v-model="form.sorts" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>

                        </a-form-model-item>

                        <a-form-model-item label="是否启用">
                            <a-switch @change="onChange"  v-model="form.is_open"/>
                        </a-form-model-item>

                        <div class="edit-project-line mb40"></div>

                        <a-form-model-item label="站点简介" class="mt20">
                            <html-edit v-model="form.site_intro"></html-edit>
                        </a-form-model-item>

                        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                            <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                                确定保存
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>

                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import uploadImg from '../../../components/upload/upload.vue';
import htmlEdit from '../../../components/html.vue';
import BaiduMap from '../../../components/map/baidumap.vue';
import moment from 'moment';
export default{
    components:{
        uploadImg,
        htmlEdit,
        BaiduMap
    },
    data(){
        return {
            headers: {
                authorization: 'authorization-text',
            },
            formItemLayout: {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 4},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 20},
                },
            },
            rules:{
                name: [
                    { required: true, message: '请填写站点名称', trigger: 'blur' },
                ],
                site_type_id:[
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ],
                account_id:[
                    { required: true, message: '请绑定一个站长', trigger: 'blur' },
                ],
                site_type:[
                    { required: true, message: '请选择站点类型', trigger: 'blur' },
                ],
                site_logo:[
                    { required: true, message: '请上传站点Logo', trigger: 'change' },
                ],
                business_license:[
                    { required: true, message: '营业执照不能为空', trigger: 'change' },
                ],
                site_phone:[
                    { required: true, message: '站点联系电话不能为空', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
                // province_id: [
                //     { required: true, trigger: 'change',validator:this.checkRegion }
                // ],
                lat: [
                    { required: true, message: '请填写经纬度', trigger: 'blur',validator:this.checkLatLng },
                ],
                expire_time:[
                    { required: true, message: '请选择到期时间', trigger: 'change' }
                ],
            },
            dateFormat: 'YYYY-MM-DD',
            loading:false,
            confirmLoading:false,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            type_list:{},
            account_list:{},
            form: {
                name:'',//站点名称
                site_abbreviation:'',//站点简称
                shop_id:0,
                account_id:0,
                site_type_id:[],//站点分类
                site_type:0,//站点类型
                is_run:0,//营业状态
                site_logo:'',//站点logo
                business_license:'',//营业执照
                site_phone:'',//电话号码
                province_id:330000,
                city_id:'',
                district_id:'',
                street_id:'',
                address:'',
                lat:'',
                lng:'',
                expire_time:new Date().getFullYear() +'-'+ (new Date().getMonth() + 1) +'-'+ new Date().getDate(),//到期时间
                sorts:'',//排序
                is_open:true,//是否启用
                site_intro:'',
                start_time:'09:00:00',
                end_time:'17:00:00',
                business_time:'',
                domains: [],

            },
            provinceData:[],
            cityData:{},
            districtData:{},
            streetData:{},
        }
    },
    created() {
        if (!this.$route.query.id) {
            this.form.shop_id = 0;
        } else {
            this.form.shop_id = parseInt(this.$route.query.id);
        }
        this.loaddata();

    },
    computed:{
        getTitle(){
            if(!this.$route.query.id){
                return '添加站点';
            }else{
                return '编辑站点';
            }
        }
    },
    methods:{
        moment,
        disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().endOf('day');
        },
        checkLatLng(rule, value, callback){
            if (!value) {
                return callback('请填写经纬度');
            }
            if(!this.form.lng){
                return callback('请填写经纬度');
            }
            return callback()
        },
        onSearch(value) {
            console.log(value);
        },
        onChange(checked) {
            console.log(`a-switch to ${checked}`);
        },
        changeTime(date, dateString,type){
            if(type === 'expire_time'){
                this.form.expire_time = dateString;
            }
            if(type === 'start_time'){
                this.form.start_time = dateString;
            }
            if(type === 'end_time'){
                this.form.end_time = dateString;
            }

        },
        removeDomain(item) {
            let index = this.form.domains.indexOf(item);
            if (index !== -1) {
                this.form.domains.splice(index, 1);
            }
        },
        addDomain() {
            console.log(111)
            this.form.domains.push({
                value: '',
                icon: '',
            });
        },
        loaddata(){
            if(this.loading===true) return;
            this.loading = true;
            this.$http.api('platform/site/getShowSite',{
                shop_id:this.form.shop_id
            }).then(res=>{
                if(res.detail instanceof Object){
                    if(Object.keys(res.detail).length !== 0){
                        this.form = res.detail;
                        this.form.expire_time = res.detail.expire_time_format;
                        this.form.is_open = this.form.is_open === 1;
                        this.form.city_id = this.form.city_id === 0 ? '' :this.form.city_id;
                        this.form.district_id = this.form.district_id === 0 ? '' :this.form.district_id;
                        this.form.province_id = this.form.province_id === 0 ? '' :this.form.province_id;
                        this.form.street_id = this.form.street_id === 0 ? '' :this.form.street_id;

                    }
                }
                this.getRegion(0,1,true);
                this.type_list = res.type_list;
                this.account_list = res.account_list;
                this.loading = false;
            }).catch(res=>{
                console.log(res);
                this.loading = false;
                this.$router.go(-1);
            })
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if(this.confirmLoading===true) return;
                    this.confirmLoading=true;
                    //console.log(this.form);
                    this.$http.api('platform/site/saveSite',this.form).then(res=>{
                        this.$message.success('保存成功',1,()=>{
                            this.confirmLoading=false;
                            this.$router.go(-1);
                        });
                    }).catch(res=>{
                        console.log(res);
                        this.confirmLoading=false;
                    })
                } else {
                    this.$message.error('必填项不能为空');
                    return false;
                }
            });

        },
        getRegion(value,obj,isInit = false) {

            let level = 1;
            if (obj != 1) {
                level = parseInt(obj.data.attrs['data-level']);
            }
            // if (isInit) {
            //     this.form.province_id = 330000;
            //     this.form.city_id = 330100;
            //     this.form.district_id = 330102;
            // }
            this.$http.api('platform/site/getRegion', {
                level: level,
                parent_id: value,
                province_id: this.form.province_id,
                city_id: this.form.city_id,
                district_id: this.form.district_id,
                is_init: isInit ? 1 : 0
            }).then(res => {
                if (isInit) {
                    this.provinceData = res.lists;
                    this.districtData = res.districtData;
                    //this.form.district_id = res.districtData[0].id;
                    this.cityData = res.cityData;
                    //this.form.city_id = res.cityData[0].id;
                    this.streetData = res.streetData
                    // this.form.street_id = res.streetData[0].id;

                }
                if (level == 1) {
                    this.provinceData = res.lists;
                }
                if (level == 2) {
                    this.cityData = res.lists;
                    this.form.city_id = res.lists[0].id;
                    this.form.district_id = null;
                    this.form.street_id = null;
                }
                if (level == 3) {
                    this.districtData = res.lists
                    this.form.district_id = null;
                    this.form.street_id = null;
                }
                if (level == 4) {
                    this.streetData = res.lists
                    this.form.street_id = null;
                }

            }).catch(res => {
                console.log(res);
            })
        },
        selectAct(e){
            this.form.lat = e.lat;
            this.form.lng = e.lng;
            this.form.address = e.keyword;
            console.log(e.keyword)
        }
    }
}
</script>

<style>
.edit-project-line{
    width: 100%;
    height: 1px;
    background: #EBEDF5;
}

.edit-project-details-text {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #5333FF;
}

.mobile-content{
    width: 750px;
}
.mobile-content{

}

.mobile-item{
    position: relative;
}
.mobile-item .action{
    position: absolute;
    left: 0;
    top: -15px;
    width: 100%;
    z-index: 2;
}
.del-action{
    width: 30px;
    height: 30px;
    background: rgba(0,0,0,.5);
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    color:#FFFFFF;
    position: absolute;
    right: -15px;
    top:-15px;
    z-index: 3;
}
.up-action,.down-action{
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    color:#007AFF;
    box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);

}
.editSite .ant-select-enabled{
    margin-right: 10px;
}
.editSite .ant-time-picker{
    margin-right: 10px;
}
i.dynamic-delete-button{
    position: absolute;
    right: -150px;
    top: 4px;
}
.upload-icon{
    display: inline-block;
}
.upload-icon > .wyu-upload-box{
    width: 60px;
    height: 60px;
    position: absolute;
    top: -27px;
    right: -100px;
}
.upload-icon > .wyu-upload-box img{
    width: 60px;
    height: 60px;
}
/*.dynamic-delete-button-s{*/
/*    right: -10px;*/
/*    top: 0;*/
/*}*/
</style>
